import type { IPicture } from '../Picture'
import { mixins, theme as tf1infoTheme } from '../../../styles'
import { Picture } from '../Picture'
import { Link } from '../../atoms/Link'

export interface ISponsorBanner {
  link: string
  pictures: IPicture
  title: string
  type?: string
  withPreload?: boolean
  theme?: 'dark' | 'light'
}

export function SponsorBanner({
  link,
  pictures,
  title,
  type,
  withPreload,
  theme = 'light',
}: ISponsorBanner): JSX.Element {
  if (!pictures?.elementList?.length) return null

  const ContainerComponent = link ? Link : 'div'
  const containerProps = link ? { url: link, type } : {}

  return (
    <>
      <div className="SponsorBanner flex justify-center items-center">
        <div className="SponsorBanner__Content flex items-center">
          {title ? <span className="SponsorBanner__Label">{title}</span> : null}
          <ContainerComponent {...containerProps}>
            <Picture
              alt={pictures.alt}
              elementList={pictures.elementList}
              lazyload={pictures.lazyload}
              placeholderTheme="light"
              withPreload={withPreload}
            />
          </ContainerComponent>
        </div>
      </div>

      <style jsx>{`
        .SponsorBanner {
          width: 100%;
          padding: 10px ${tf1infoTheme.cssVars.marginLR};
          height: 52px;
          background-color: ${theme === 'dark'
            ? tf1infoTheme.cssVars.deepBlue
            : tf1infoTheme.cssVars.lightBlue};
        }

        :global([data-module='article-sponso']) .SponsorBanner {
          background-color: ${tf1infoTheme.cssVars.lightBlue};
        }

        .SponsorBanner__Content {
          max-width: ${tf1infoTheme.breakpoints.xl}px;
          color: ${theme === 'dark' ? tf1infoTheme.cssVars.white : tf1infoTheme.cssVars.deepBlue};
          gap: 10px;
          font-size: 11px;
          font-weight: 600;
        }

        .SponsorBanner__Content :global(a) {
          column-gap: 9px;
        }

        .SponsorBanner :global(img) {
          height: 42px;
        }

        .SponsorBanner :global(amp-img) {
          width: 70px;
          height: 100%;
        }

        .SponsorBanner :global(figure) {
          height: 42px;
          margin: 0;
        }

        .SponsorBanner__Label {
          ${mixins.fonts.base.xsmall}
          color: ${theme === 'dark' ? tf1infoTheme.cssVars.white : tf1infoTheme.cssVars.deepBlue};
          font-size: 11px;
          font-weight: 600;
        }

        @media ${mixins.mediaQuery.tablet} {
          .SponsorBanner__Label {
            color: ${theme === 'dark' ? tf1infoTheme.cssVars.white : tf1infoTheme.cssVars.deepBlue};
            font-size: 14px;
            font-weight: 400;
          }
        }
      `}</style>
    </>
  )
}
