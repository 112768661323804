import type { IPictureElementList } from '../PictureElementList'
import { Link } from '../Link'
import { Icon } from '../Icon'
import { PictureElementList } from '../PictureElementList'
import { DurationLabel } from '../DurationLabel'
import { theme } from '../../../styles'
import { styles } from './styles'

export interface IArticleCard {
  index?: number
  isCard?: boolean
  isCarousel?: boolean
  isVideo?: boolean
  link: string
  pictures?: IPictureElementList
  title: string
  isBlured?: boolean
  hasMultiLive?: boolean
  onClick?: () => void
  ratio?: string
  pictureLazyload?: boolean
  pictureImportance?: 'auto' | 'high'
  picturePriority?: 'auto' | 'high'
  duration?: number
  isHeadlines?: boolean
}

export function ArticleCard({
  index,
  isCard,
  isCarousel,
  isVideo,
  link,
  pictures,
  title,
  isBlured = true,
  hasMultiLive,
  onClick,
  pictureLazyload,
  pictureImportance,
  picturePriority,
  ratio,
  duration,
  isHeadlines = false,
}: IArticleCard): JSX.Element {
  const opacity: number = isBlured ? 0.6 : 1
  const lazyload = pictureLazyload !== undefined ? pictureLazyload : index !== 0
  const importance = pictureImportance ? pictureImportance : 'low'
  const fetchPriority = picturePriority ? picturePriority : 'low'

  const TitleTag = isHeadlines ? 'span' : 'h2'

  return (
    <>
      <div
        className={[
          'ArticleCard__Content flex flex-column justify-end',
          isCard ? 'ArticleCard__Content_Card' : '',
          isCarousel ? 'ArticleCard__Carousel_Card' : '',
          `ArticleCard__Content-${index}`,
        ].join(' ')}
      >
        <Link url={title ? null : link} onClick={title ? null : onClick}>
          <PictureElementList
            elementList={pictures?.elementList}
            lazyload={lazyload}
            importance={importance}
            fetchPriority={fetchPriority}
            alt={title}
          />
        </Link>
        <DurationLabel duration={duration} className="ArticleCard__Duration" />
        {isVideo && (
          <Icon
            name="play-circle"
            className="ArticleCard__PlayIcon"
            secondaryColor={theme.cssVars.white}
            size="30px"
          />
        )}
        {title && (
          <div className="ArticleCard__Title">
            {hasMultiLive && (
              <span
                className="ArticleCard__Title__DirectIcon"
                data-testid="ArticleCard__Title__DirectIcon"
              >
                <Icon name="direct" size="20px" />
              </span>
            )}
            <TitleTag className="ArticleCard__Title__Text">
              <Link url={link} onClick={onClick}>
                {title}
              </Link>
            </TitleTag>
          </div>
        )}
      </div>
      <style jsx>{styles}</style>
      <style jsx>{`
        .ArticleCard__Content :global(img) {
          opacity: ${opacity};
        }
      `}</style>
      <style jsx>{`
        .ArticleCard__Content {
          aspect-ratio: ${ratio || 'initial'};
        }
      `}</style>
    </>
  )
}
